import React, { useEffect, useMemo, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import daygrid from "@fullcalendar/daygrid";
import timegrid from "@fullcalendar/timegrid";
import { useApi } from "../../components/hooks";
import { DateTime } from "luxon";
import { Outlet, useNavigate } from "react-router-dom";
import interaction from "@fullcalendar/interaction";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Box, Button, Divider, Paper, Tab, Tabs } from "@mui/material";
import "./FullCalendar.css";

function getInitials(name) {
    // Trim to ensure no leading/trailing spaces affect the split
    const parts = name.trim().split(/\s+/);
    // Map over the parts and take the first character as uppercase
    const initials = parts.map((part) => part.charAt(0).toUpperCase());

    // Join them with a dot. If there's only one name, it will simply return one initial
    return initials.join(".") + (initials.length > 0 ? "." : "");
}

const AppointmentCalendar = () => {
    const getLoggedUserData = () => {
        const loggedUserAsString = localStorage.getItem("loggedUser");
        if (!loggedUserAsString) return {};
        const data = JSON.parse(loggedUserAsString);
        return data;
    };

    const [appointments, setAppointments] = useState([]);
    const [activeUser, setActiveUser] = useState(getLoggedUserData()?._id);
    const [users, setUsers] = useState([]);
    const [values, setValues] = useState({});
    const { loading, fetch } = useApi();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        if (values.date) navigate("create");
    }, [values]);

    const appointmentEvents = useMemo(() => {
        return appointments
            ?.filter((appointment) => {
                if (!activeUser) return true;
                return appointment.user_id === activeUser;
            })
            ?.map((appointment) => {
                const usernameInitials = getInitials(appointment.username || "");
                const patientFullName = appointment.patient_name + " " + (appointment.patient_lastname || "");
                const title = patientFullName + " - " + usernameInitials;
                return {
                    id: appointment._id,
                    title: title,
                    start: appointment.date + "T" + appointment.start_time + ":00",
                    end: appointment.date + "T" + appointment.end_time + ":00",
                    borderColor: appointment.user_color,
                    textColor: "black",
                    backgroundColor: (appointment.user_color || "#808080") + "4D",
                    user_id: appointment.user_id,
                };
            });
    }, [appointments, activeUser]);

    const loadAppointments = async (startDate, endDate) => {
        if (!startDate || !endDate) return;
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "appointments",
                    responseData:
                        "_id date name username user_id user_color start_time end_time patient_name patient_lastname",
                    data: {
                        startDate,
                        endDate,
                    },
                },
                {
                    endpoint: "users",
                    data: { activeOnly: true },
                    responseData: "_id username name",
                },
            ],
        });
        if (response?.appointments) {
            setAppointments(response.appointments);
        }
        if (response?.users) {
            setUsers(response.users?.filter((user) => user.username !== "sysadmin"));
        }
    };

    const saveAppointmentChanges = async ({ _id, newDate, newStartTime, newEndTime }) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateAppointment",
            data: {
                _id,
                date: newDate,
                start_time: newStartTime,
                end_time: newEndTime,
            },
            responseData: "_id",
        });
        if (response?.updateAppointment?._id) {
            enqueueSnackbar(t("appointment_updated"), { variant: "info" });
            return;
        }
        setAppointments([...appointments]);
    };

    const addAppointment = (appointment) => {
        setAppointments([...appointments, appointment]);
    };

    const removeAppointment = (id) => {
        setAppointments(appointments?.filter((a) => a._id !== id));
    };

    return (
        <div style={{ height: "100vh" }}>
            <Tabs
                value={activeUser}
                onChange={(e, userId) => setActiveUser(userId)}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
            >
                {users.map((user) => (
                    <Tab value={user._id} sx={{ alignItems: "flex-start" }} label={t(user.name)} />
                ))}
            </Tabs>
            <Divider sx={{ marginBottom: 3 }} />
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        setValues({
                            date: DateTime.now().toFormat("yyyy-LL-dd"),
                        });
                    }}
                    sx={{ marginBottom: 2 }}
                >
                    {t("new")}
                </Button>
            </Box>
            <FullCalendar
                allDaySlot={false}
                slotLabelFormat={{ hour: "numeric", minute: "2-digit", hour12: false }}
                headerToolbar={{ left: "timeGridWeek,timeGridToday" }}
                height="100%"
                plugins={[daygrid, timegrid, interaction]}
                nowIndicator
                editable
                selectable
                initialView={"timeGridWeek"}
                longPressDelay={1}
                views={{
                    timeGridToday: {
                        type: "timeGrid",
                        duration: { days: 1 },
                    },
                }}
                events={appointmentEvents}
                eventOverlap={(behindEvent, changedEvent) => {
                    const behindEventUser = behindEvent._def?.extendedProps?.user_id;
                    const changedEventUser = changedEvent._def?.extendedProps?.user_id;
                    if (behindEventUser !== changedEventUser) return true;
                    return false;
                }}
                eventClick={({ event }) => navigate(`${event.id?.split("appointments_")?.[1]}`)}
                eventDrop={({ event }) => {
                    const newStartDate = DateTime.fromISO(event.start.toJSON());
                    const newEndDate = DateTime.fromISO(event.end.toJSON());
                    saveAppointmentChanges({
                        _id: event.id,
                        newDate: newStartDate.toFormat("yyyy-LL-dd"),
                        newStartTime: newStartDate.toFormat("HH:mm"),
                        newEndTime: newEndDate.toFormat("HH:mm"),
                    });
                }}
                eventResize={({ event }) => {
                    const newStartDate = DateTime.fromISO(event.start.toJSON());
                    const newEndDate = DateTime.fromISO(event.end.toJSON());
                    saveAppointmentChanges({
                        _id: event.id,
                        newDate: newStartDate.toFormat("yyyy-LL-dd"),
                        newStartTime: newStartDate.toFormat("HH:mm"),
                        newEndTime: newEndDate.toFormat("HH:mm"),
                    });
                }}
                select={({ startStr, endStr }) => {
                    setValues({
                        date: DateTime.fromISO(startStr).toFormat("yyyy-LL-dd"),
                        start_time: DateTime.fromISO(startStr).toFormat("HH:mm"),
                        end_time: DateTime.fromISO(endStr).toFormat("HH:mm"),
                    });
                }}
                datesSet={({ startStr, endStr }) => {
                    const startDate = DateTime.fromISO(startStr).toFormat("yyyy-LL-dd");
                    const endDate = DateTime.fromISO(endStr).minus({ days: 1 }).toFormat("yyyy-LL-dd");
                    loadAppointments(startDate, endDate);
                }}
            />
            <Outlet
                context={{
                    ...values,
                    user_id: activeUser,
                    onSaveEffect: addAppointment,
                    onDeleted: removeAppointment,
                }}
            />
        </div>
    );
};

export default AppointmentCalendar;
