import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { DateTime } from "luxon";

export const printEstimate = async ({ estimateId, fetch, t, currenciesValues, returnBase64 }) => {
    const endpoints = [
        {
            endpoint: "settings",
            responseData: `_id email phone social_accounts{network account} name address`,
        },
        {
            endpoint: "services",
            responseData: `_id name`,
        },
        {
            endpoint: "estimate",
            responseData: `_id code patient_name patient_lastname patient_phone patient_email username table_data{tooth treatment price description} discount total created_at`,
            data: {
                _id: estimateId,
            },
        },
    ];
    const response = await fetch({
        operation: "query",
        multipleEndpoints: endpoints,
    });
    const services = response?.services;
    const { email = "", phone = "", social_accounts, name = "", address = "" } = response?.settings || {};
    const {
        patient_name,
        patient_lastname,
        patient_phone,
        patient_email,
        username,
        total,
        discount,
        created_at,
        code,
    } = response?.estimate || {};
    let { table_data } = response?.estimate || {};
    table_data = table_data.map((td) => {
        const serviceName = services.find((service) => service._id === td.treatment)?.name;
        return {
            ...td,
            treatmentName: serviceName,
        };
    });

    const doc = new jsPDF("p", "mm", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    let row = 20;
    doc.text(name, 15, row);
    row += 10;
    doc.setFontSize(15);
    doc.text(address || "", 15, row);

    autoTable(doc, {
        head: [
            [
                t("Patient"),
                t("Clinic"),
                t("Estimate_code") + " " + code,
                t("Date"),
                !!created_at ? DateTime.fromISO(created_at).toFormat("yyyy LLL dd HH:mm") : "",
            ],
        ],
        body: [
            [(patient_name || "") + " " + (patient_lastname || ""), name],
            [patient_email || "", email || ""],
            [patient_phone || "", phone || ""],
        ],
        theme: "plain",
        margin: { top: 40 },
    });
    table_data = table_data?.map((data) => [
        data?.tooth,
        data?.treatmentName,
        parseFloat(data?.price || 0.0).toFixed(2) + " ALL",
        data?.description,
    ]);
    const body = table_data.concat([
        [
            { content: "", styles: { fillColor: "white" } },
            { content: "", styles: { fillColor: "white" } },
            { content: t("Discount"), styles: { fontStyle: "bold", fillColor: "white" } },
            { content: discount || "", styles: { fontStyle: "bold", fillColor: "white" } },
        ],
        [
            { content: "", styles: { fillColor: "white" } },
            { content: "", styles: { fillColor: "white" } },
            { content: t("Total"), styles: { fontStyle: "bold", fillColor: "white" } },
            {
                content: (parseFloat(total).toFixed(2) || "") + " ALL",
                styles: { fontStyle: "bold", fillColor: "white" },
            },
        ],
    ]);

    if (!!currenciesValues) {
        currenciesValues?.forEach((currency) => {
            const convertedTotal = parseFloat(total) * parseFloat(currency?.rate);
            if (isNaN(convertedTotal)) return;
            body.push([
                { content: "", styles: { fillColor: "white" } },
                { content: "", styles: { fillColor: "white" } },
                { content: t("Total") + " " + currency.currency, styles: { fontStyle: "bold", fillColor: "white" } },
                {
                    content: convertedTotal.toFixed(2) + " EUR",
                    styles: { fontStyle: "bold", fillColor: "white" },
                },
            ]);
        });
    }

    autoTable(doc, {
        head: [[t("tooth"), t("treatment"), t("price"), t("description")]],
        margin: { top: 75 },
        body: body,
        columnStyles: {
            3: { cellWidth: 50 },
        },
    });
    doc.text(`Dr. ${username || ""}`, pageWidth - 60, pageHeight - 70);
    doc.text(`____________`, pageWidth - 60, pageHeight - 60);
    doc.setFillColor(217, 217, 217);
    doc.rect(10, pageHeight - 50, pageWidth - 20, 45, "F");
    doc.addImage("/icons/callIcon.png", "png", 20, pageHeight - 34, 5, 5);
    doc.text(phone || "", 30, pageHeight - 30);
    doc.addImage("/icons/emailIcon.png", "png", pageWidth / 2, pageHeight - 34, 5, 5);
    doc.text(email || "", pageWidth / 2 + 10, pageHeight - 30);
    social_accounts?.map((account, i) => {
        const networkImage =
            account.network === "facebook"
                ? "/icons/facebookIcon.png"
                : account.network === "instagram"
                ? "/icons/instagramIcon.png"
                : null;
        if (i % 2 !== 0) {
            if (networkImage) doc.addImage(networkImage, "png", pageWidth / 2, pageHeight - 20, 5, 5);
            doc.text(`${account?.account || ""}`, pageWidth / 2 + 10, pageHeight - 16);
        } else {
            if (networkImage) doc.addImage(networkImage, "png", 20, pageHeight - 20, 5, 5);
            doc.text(`${account?.account || ""}`, 30, pageHeight - 16);
        }
    });

    // Create a promise to handle image loading
    const loadImage = () => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            // Use the full URL of your application
            img.src = window.location.origin + "/images/ClinicSystemLogo.png";

            img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext("2d");
                // Fill with white background first
                ctx.fillStyle = "#FFFFFF";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0);

                const imageData = canvas.toDataURL("image/jpeg");
                resolve(imageData);
            };

            img.onerror = reject;
        });
    };

    loadImage()
        .then((imageData) => {
            doc.addImage(imageData, "PNG", (pageWidth - 70) / 2, 10, 60, 30);
            if (returnBase64) {
                const base64String = doc.output("datauristring");
                return base64String;
            }
            // doc.save("estimate");
            const base64 = doc.output("datauristring");
            const newWindow = window.open();
            newWindow.document.write(`<iframe width='100%' height='100%' src='${base64}'></iframe>`);
        })
        .catch((error) => {
            console.error("Error loading image:", error);
        });
};
